import PropTypes from "prop-types"
import React from "react"

import "./form-success-message.scss"

import Namaste from "../../images/icons/hinduism.svg"
import GooglePay from "../../images/googlePay.jpeg"

const FormSuccessMessage = props => {
  return (
    <div className="form-success form-success_services">
      <img className="form-success__icon" alt="Namaste" src={Namaste} />
      <h5>Form submission successful</h5>
      <p>
        Thank you, please make payment of{" "}
        <b>
          ₹{props.fees}(INR) or ${props.feesUSD}(USD)
        </b>{" "}
        through the options listed below. Once the payment is processed, our
        astrologers will start the calculations and contact you with the
        results.
      </p>
      {/* {props.stripePromo ? (
        <p>
          We believe in parity pricing, so only if you live in India, you may
          use the code <b>{props.stripePromo} for a 34% discount.</b>
        </p>
      ) : (
        ""
      )}
      {props.stripeLinkINR ? (
        <a href={props.stripeLinkINR} className="button button_payment">
          Pay Now
          <img className="button__services-icon" alt="Visa" src={Visa} />
          <img className="button__services-icon" alt="Master" src={Master} />
          <img className="button__services-icon" alt="Amex" src={Amex} />
        </a>
      ) : (
        ""
      )} */}
      <div className="payment-info">
        <div className="payment-info__block">
          <h5>Payment through bank</h5>
          <table>
            <tbody>
              <tr>
                <th>Name:&nbsp;&nbsp;</th>
                <td>ARAVIND R</td>
              </tr>
              <tr>
                <th>Bank:&nbsp;&nbsp;</th>
                <td>South Indian Bank, Ernakulam North branch</td>
              </tr>
              <tr>
                <th>IFSC code:&nbsp;&nbsp;</th>
                <td>SIBL0000485</td>
              </tr>
              <tr>
                <th>AC No.:&nbsp;&nbsp;</th>
                <td>0485053000012631</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="payment-info__block">
          <h5>GooglePay</h5>

          <img className="googlepay-code" alt="" src={GooglePay} />
          <p>
            <b>GooglePay id: </b>astrologeraravind@okicici
          </p>
        </div>
      </div>
    </div>
  )
}

FormSuccessMessage.propTypes = {
  fees: PropTypes.number,
  feesUSD: PropTypes.number,
  stripePromo: PropTypes.string,
  stripeLinkINR: PropTypes.string,
  // stripeLinkUSD: PropTypes.string,
}

FormSuccessMessage.defaultProps = {
  fees: 500,
  feesUSD: 10,
}

export default FormSuccessMessage
