import React, { useState } from "react"
// import { Link } from "gatsby"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormSuccessMessage from "../../components/form-success-message/form-success-message"

import "../../styles/services.scss"

const HoroscopePrediction = () => {
  const [submissionStatus, setSubmissionStatus] = useState("")
  const handleSubmissionStatus = axiosStatus => {
    setSubmissionStatus(axiosStatus)
  }

  return (
    <Layout>
      <SEO
        title="Horoscope prediction"
        description="Find out what the future holds for you from your Horoscope."
        keyword="Horoscope prediction, astrology, online, suffering, life"
      />
      <div className="main-sub-wrapper">
        <div className="header-wrap">
          <h1 className="header__title h1_decorative">Horoscope prediction</h1>
          {/* <div className="tg-list-item">
            <input
              className="tgl tgl-flip"
              id="language-checkbox"
              type="checkbox"
            />
            <label
              className="tgl-btn"
              data-tg-off="മലയാളം"
              data-tg-on="English"
              htmlFor="language-checkbox"
            ></label>
          </div> */}
          <div className="text-left">
            <p>Find out what the future holds for you from your Horoscope.</p>
          </div>
        </div>
        <div className="service-inner-wrap">
          <h2 className="h4">Process and Fees</h2>
          <p>
            Once you submit the form and make the payment, our astrologers get
            to the calculations and will give you the result over the contact
            address you mention in the email. If you have questions after
            reading the result, then you can always communicate with our
            astrologers over email, phone, Skype etc.
          </p>
          <p>
            <b>Fees:</b> ₹500 (INR) or $10 (USD)
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              applicantRelationship: "",
              personName: "",
              personGender: "",
              bday: "",
              personTob: "",
              personPob: "",
              personStar: "",
              focusAreas: [],
              message: "",
            }}
            validate={values => {
              const errors = {}
              if (!values.name) {
                errors.name = "Please enter you name"
              }
              if (!values.email) {
                errors.email =
                  "Please enter you e-mail or phone number so that we could contact you"
              } else if (
                !(
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  ) || /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(values.email)
                )
              ) {
                errors.email = "Invalid email or number"
              }
              if (!values.applicantRelationship) {
                errors.applicantRelationship =
                  "Please enter you relationship with the person"
              }
              if (!values.personName) {
                errors.personName = "Please enter person's name"
              }
              if (!values.personGender) {
                errors.personGender = "Please enter person's gender"
              }
              if (!values.bday) {
                errors.bday = "Please enter person's date of birth"
              }
              if (!values.personTob) {
                errors.personTob = "Please enter person's time of birth"
              }
              if (!values.personPob) {
                errors.personPob = "Please enter person's place of birth"
              }
              if (!values.personStar) {
                errors.personStar = "Please enter person's star"
              }
              if (values.focusAreas.length === 0) {
                errors.focusAreas =
                  "Please select the aspects of your life that you want us to focus"
              }
              if (values.policy === true) {
                errors.policy = "Please check to proceed"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              axios
                // .post(
                //   "https://getform.io/f/a2c6299e-045d-4a63-a8d1-22b082f6bbb4",
                //   values
                // )
                .post("https://submit-form.com/oczZxf9X", values)
                .then(res => {
                  handleSubmissionStatus("success")
                })
                .catch(err => {
                  handleSubmissionStatus("failure")
                  console.log(err)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                {submissionStatus === "failure" ? (
                  <p className="form__main-error">
                    Sorry, please send again, the message wasn't sent.
                  </p>
                ) : (
                  ""
                )}

                {submissionStatus === "success" ? (
                  <FormSuccessMessage
                    fees={500}
                    feesUSD={10}
                    stripePromo="INDIA33"
                    stripeLinkINR="https://buy.stripe.com/00g7t2fhm3X36vC9AB"
                  ></FormSuccessMessage>
                ) : (
                  <>
                    <h6 className="form__section-title">
                      Details of you - the applicant
                    </h6>
                    <div className="form__group">
                      <label htmlFor="name" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="name"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="email" className="form__label">
                        Contact Info*
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        autocomplete="email"
                        className="form__input"
                        aria-required="true"
                        placeholder="e-mail or phone number"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label
                        htmlFor="applicantRelationship"
                        className="form__label"
                      >
                        Relationship with the person*
                      </label>
                      <Field
                        type="text"
                        name="applicantRelationship"
                        id="applicantRelationship"
                        className="form__input"
                        placeholder="I am his/her"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="applicantRelationship"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <h6 className="form__section-title">
                      Details of the person
                    </h6>
                    <div className="form__group">
                      <label htmlFor="personName" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="personName"
                        id="personName"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personName"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personGender" className="form__label">
                        Gender*
                      </label>
                      <Field
                        as="select"
                        name="personGender"
                        id="personGender"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Field>
                      <ErrorMessage
                        name="personGender"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="bday" className="form__label">
                        Date of birth*
                      </label>
                      <Field
                        type="date"
                        max={
                          new Date(
                            new Date().getTime() -
                            new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        name="bday"
                        id="bday"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="bday"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personTob" className="form__label">
                        Time of birth*
                      </label>
                      <Field
                        type="time"
                        name="personTob"
                        id="personTob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personTob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personPob" className="form__label">
                        Place of birth* (nearest city)
                      </label>
                      <Field
                        type="text"
                        name="personPob"
                        id="personPob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personPob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personStar" className="form__label">
                        Nakshatra/Birthstar*
                      </label>
                      <Field
                        as="select"
                        name="personStar"
                        id="personStar"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="unknown">
                          Unknown (its fine, we will calculate it.)
                        </option>
                        <option value="aswathi">Aswathi/Ashwini</option>
                        <option value="bharani">Bharani</option>
                        <option value="karthika">Karthika/Krittika</option>
                        <option value="rohini">Rohini</option>
                        <option value="makeeram">Makeeram/Mrigashirsa</option>
                        <option value="thiruvathira">Thiruvathira/Ardra</option>
                        <option value="punartham">
                          Punartham/Punarvasu/Punarpoosam
                        </option>
                        <option value="pooyam">Pooyam/Pushya</option>
                        <option value="aayilyam">Aayilyam/Ashlesha</option>
                        <option value="makam">Makam/Magha</option>
                        <option value="pooram">Pooram/Purva Phalguni</option>
                        <option value="uthram">Uthram/Uttara Phalguni</option>
                        <option value="atham">Atham/Hasta</option>
                        <option value="chithira">Chithira/Chitra</option>
                        <option value="chothi">Chothi/Swati</option>
                        <option value="vishaakam">Vishaakham/Vishakha</option>
                        <option value="anizham">
                          Anizham/Anuradha/Anusham
                        </option>
                        <option value="thriketta">
                          Thriketta/Jyeshtha/Kettai
                        </option>
                        <option value="moolam">Moolam/Mula</option>
                        <option value="pooraadam">Pooraadam/Purvashada</option>
                        <option value="uthraadam">Uthraadam/Uthrashada</option>
                        <option value="thiruvonam">Thiruvonam/Shravana</option>
                        <option value="avittam">Avittam/Dhanishtha</option>
                        <option value="chathayam">Chathayam/Satabisha</option>
                        <option value="pooruruttaathi">
                          Pooruruttaathi/Poorva bhadrapada
                        </option>
                        <option value="uthrattathi">
                          Uthrattathi/Uttara bhadrapada
                        </option>
                        <option value="revathi">Revathi</option>
                      </Field>
                      <ErrorMessage
                        name="personStar"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <fieldset className="form__group">
                      <legend htmlFor="education">Areas to focus</legend>
                      <label htmlFor="education">
                        <Field
                          type="checkbox"
                          name="focusAreas"
                          id="education"
                          value="education"
                        />
                        Education
                      </label>
                      <label htmlFor="career">
                        <Field
                          type="checkbox"
                          name="focusAreas"
                          id="career"
                          value="career"
                        />
                        Career
                      </label>
                      <label htmlFor="marriage">
                        <Field
                          type="checkbox"
                          name="focusAreas"
                          id="marriage"
                          value="marriage"
                        />
                        Marriage
                      </label>
                      <label htmlFor="health">
                        <Field
                          type="checkbox"
                          name="focusAreas"
                          id="health"
                          value="health"
                        />
                        Health
                      </label>
                      <label htmlFor="familyHarmony">
                        <Field
                          type="checkbox"
                          name="focusAreas"
                          id="familyHarmony"
                          value="familyHarmony"
                        />
                        Family harmony
                      </label>
                      <label htmlFor="financialTroubles">
                        <Field
                          type="checkbox"
                          name="focusAreas"
                          id="financialTroubles"
                          value="financialTroubles"
                        />
                        Financial troubles
                      </label>
                      <ErrorMessage
                        name="focusAreas"
                        component="p"
                        className="form__unit-error"
                      />
                    </fieldset>
                    <div className="form__group">
                      <label htmlFor="message" className="form__label">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        rows="4"
                        name="message"
                        id="message"
                        className="form__input"
                      />
                      <ErrorMessage
                        name="message"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="hidden">
                      <label>Do you align with our policy</label>
                      <Field name="policy" type="checkbox"></Field>
                    </div>
                    <div className="button-wrap">
                      <button
                        className="button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Apply
                      </button>
                      <button className="link_text" type="reset">
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  )
}

export default HoroscopePrediction
